(function (window, document) {
  if (document.scripts.namedItem("gainsight_tag")) {
    var this_script = document.scripts.namedItem("gainsight_tag");
    var gainsight_tag_key = this_script.getAttribute("data-gainsight-tag-key");
    if (gainsight_tag_key === "") {
      throw new Error("Gainsight key missiing.");
    }
    (function (n, t, a, e, co) {
      var i = "aptrinsic";
      (n[i] =
        n[i] ||
        function () {
          (n[i].q = n[i].q || []).push(arguments);
        }),
        (n[i].p = e);
      n[i].c = co;
      var r = t.createElement("script");
      (r.async = !0), (r.src = a + "?a=" + e);
      var c = t.getElementsByTagName("script")[0];
      c.parentNode.insertBefore(r, c);
    })(
      window,
      document,
      "https://web-sdk.aptrinsic.com/api/aptrinsic.js",
      gainsight_tag_key
    );
  }
})(window, document);
